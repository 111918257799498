
import React, { useEffect, useLayoutEffect, useState } from 'react'
import FormSection from './FormSection';
import Card from '../UI Component/Card';
import { useForm, useFieldArray } from "react-hook-form";
import axios from 'axios';
import { Snackbar } from '@mui/material';
import { useUpdateSingle } from '../../hooks/Api';
import { useNavigate, useParams } from 'react-router-dom';
import { useFetch } from '../../hooks/Api';
import { Select } from 'antd';
const Edit_Form = () => {
    const apiname = process.env.REACT_APP_API_FORM?.toString();

    const editApiname = process.env.REACT_APP_API_FORMS?.toString();
    const { id } = useParams()
    const localStorageData = localStorage.getItem('items')
    const tokenData = localStorageData ? JSON.parse(localStorageData) : null
    const [membershipValue,setMembershipValue] = useState()

    const [membershipOptions,setMembershipOptions] = useState([])

    const { mutate, isSuccess, isError, error, data: Updated_response, isLoading } = useUpdateSingle()

    const [details, setdetails] = useState()
    const [getData, setGetData] = useState([])
    const navigate = useNavigate()

    useLayoutEffect(() => {
        getdetails()
    }, [])

    const getdetails = async () => {

        const APIDATA = await axios.get(`${process.env.REACT_APP_BASE_URL}${apiname}/${id}`, {
            headers: {
                'authorization': `Bearer ${tokenData?.token}`
            }
        })
        setdetails(APIDATA.data.results)
        const getModifyData = await modifyData(APIDATA.data.results)
        setGetData(getModifyData)
    }
    const { register, control, handleSubmit, setValue, getValues, watch, formState: { errors }, value, replace, reset, clearErrors } = useForm({
        defaultValues: {
            formData: getData
        }
    });


    useEffect(() => {
        if (details) {
            reset({ ...details, formData: getData })
            setValue('file_name', details.file_name)

            const matchingMembership = membershipOptions?.find((e) => e.value === details?.membership_id);
      setValue('memberhship_id', matchingMembership?.value)
      setMembershipValue(matchingMembership)
        }
    }, [details,membershipOptions])

    const modifyData = (data) => {
        const detailsasdasd = []
        const parseData = JSON.parse(data?.form_fields) 
        parseData?.map((details, index) => {
            if (details.type === "checkbox") {
                details.checkBoxOption = []

                details.options.forEach((optionObject, index) => {
                    const key = Object.keys(optionObject)[0];
                    const value = optionObject[key];

                    const valueString = (typeof value === 'object' && value !== null)
                        ? JSON.stringify(value)
                        : String(value);

                    const formattedString = `${valueString}`;

                    details.checkBoxOption.push(formattedString);
                });

                delete details.options;
            }

            if (details.type === "radio") {
                details.radioOptions = []
                details.options.forEach((optionObject, index) => {
                    const key = Object.keys(optionObject)[0];
                    const value = optionObject[key];

                    const valueString = (typeof value === 'object' && value !== null)
                        ? JSON.stringify(value)
                        : String(value);

                    const formattedString = `${valueString}`;

                    details.radioOptions.push(formattedString);
                });

                delete details.options;
            }

            if (details.type === "dropdown") {
                details.option = details.options
                delete details.options;
            }
            if (details.type === "short" || details.type === "paragrph" || details.type == "date" || details.type == "sign pad") {
                delete details.checkBoxOption;
                delete details.dropDown;
                delete details.radioOptions;
                delete details.radioText;
                if (details.type === "date") {
                    details.format = "DD/MM/YYYY"
                }
            }

            return detailsasdasd.push(details)
        })
        return detailsasdasd
    }

    const { fields, append, remove, update, insert } = useFieldArray({
        control,
        name: 'formData'
    });
    const onSubmit = data => {
        const FinalData = handleformData(data)
        let UpdatedData = {
            apiname: editApiname,
            id: id,
            data: FinalData
        }
        mutate(UpdatedData)
    };

    const [state, setState] = useState({
        open: false
    });
    const handleClose = () => {
        setState({
            ...state,
            open: false,
        });
    };

    useEffect(() => {
        if (isSuccess) {
            setState({ open: true })
            navigate(-1)
        }
        else if (isError) {
            console.log(isError, "isError");
            setState({ open: true })
        }
    }, [error, isSuccess])

    const handleformData = (submitData) => {
        const formFields = [];

        submitData?.formData?.map((key, index) => {
            if (key.type === "short" || key.type === "paragrph" || key.type == "date" || key.type == "sign pad") {
                delete key.checkBoxOption;
                delete key.dropDown;
                delete key.radioOptions;
                delete key.radioText;
                delete key.radioTextData;
                delete key.dropdownOption;
                if (key.type === "date") {
                    key.format = "DD/MM/YYYY"
                }
            }

            if(key.type === 'button'){
                delete key.required
                delete key.Key
                delete key.checkBoxOption;
                delete key.dropdownOption;
                delete key.radioOptions;
                delete key.radioText;
                delete key.radioTextData;
                delete key.date;
                delete key.answer;
                delete key.button;
            }

            if (key.type === "radio") {
                delete key.checkBoxOption;
                delete key.date;
                delete key.dropDown;

                if (key.radioOptions) {
                    key.options = key.radioOptions.map((option, index) => ({
                        [`option ${index + 1}`]: option
                    }));
                }
                delete key.radioOptions;
            }

            if (key.type === "checkbox") {
                delete key.date;
                delete key.dropDown;
                delete key.radioText;
                delete key.radioOptions;
                delete key.radioTextData;

                if (key.checkBoxOption) {
                    key.options = key.checkBoxOption.map((option, index) => ({
                        [`option ${index + 1}`]: option
                    }));
                }
                delete key.checkBoxOption;
            }

            if (key.type === "dropdown") {
                delete key.date;
                delete key.radioText;
                delete key.radioOptions;
                delete key.checkBoxOption;
                delete key.radioTextData;

                if (key.dropDown) {
                    key.options = key.dropDown
                }
                delete key.dropDown;
            }
            formFields.push(key)
        })
        const finalObj = {
            form_name: submitData.form_name,
            form_description: submitData.form_description,
            file_name:details.file_name,
            form_fields: formFields,
            isPeoria:submitData?.isPeoria == 1 ? true : false,
            membership_id:submitData.membership_id
        }
        return finalObj
    }

    const addSection = (index) => {
        insert(index + 1, {
            type: 'short',
            radioOptions: [""],
            checkBoxOption: [""],
            dropdownOption: [{ label: "", value: "" }],
            label: '',
            radioText: false,
            radioTextData:[{ label:"", Key:"", answer:"" }],
            answer: "",
            Key: ""
        });
    };

    const handleRemoveField = index => {
        remove(index);
    };

    const handleInputChange = (index, field, value) => {
        setValue(`formData.${index}.${field}`, value);
        update(`formData.${index}.${field}`, value)
    };

    const handleRadioAddOption = index => {
        const currentOptions = getValues(`formData.${index}.radioOptions`) || [];
        setValue(`formData.${index}.radioOptions`, [...currentOptions, '']);
        update(`formData.${index}.radioOptions`, [...currentOptions, ''])
    };

    const handleRadioRemoveOption = (index, optionIndex) => {
        const currentOptions = getValues(`formData.${index}.radioOptions`) || [];
        currentOptions.splice(optionIndex, 1);
        setValue(`formData.${index}.radioOptions`, [...currentOptions]);
        update(`formData.${index}.radioOptions`, [...currentOptions])
    };

    const handleAddOption = (index, optionType) => {
        const currentOptions = getValues(`formData.${index}.${optionType}`) || [];
        setValue(`formData.${index}.${optionType}`, [...currentOptions, '']);
        update(`formData.${index}.${optionType}`, [...currentOptions, '']);

    };

    const handleRemoveOption = (index, optionIndex) => {
        const currentOptions = getValues(`formData.${index}.checkBoxOption`) || [];
        currentOptions.splice(optionIndex, 1);
        setValue(`formData.${index}.checkBoxOption`, [...currentOptions]);
        update(`formData.${index}.checkBoxOption`, [...currentOptions]);
    };

    const handleAddDDOption = index => {
        const currentOptions = getValues(`formData.${index}.dropdownOption`) || [];

        const newOption = { label: '', value: '' };
        const updatedOptions = [...currentOptions, newOption];

        setValue(`formData.${index}.dropdownOption`, updatedOptions);
        update(`formData.${index}.dropdownOption`, updatedOptions);

    };

    const handleRemoveDropDown = (index, optionIndex) => {
        const currentOptions = getValues(`formData.${index}.dropdownOption`) || [];
        currentOptions.splice(optionIndex, 1);
        setValue(`formData.${index}.dropdownOption`, [...currentOptions]);
        update(`formData.${index}.dropdownOption`, [...currentOptions]);
    };

    const handleDropdownChange = (index, idx, e, field) => {
        const currentOptions = getValues(`formData.${index}.dropdownOption`) || [];
        currentOptions[idx][field] = e.target.value;
        setValue(`formData.${index}.dropdownOption`, [...currentOptions]);
        update(`formData.${index}.dropdownOption`, [...currentOptions]);
    };

    const handleAddRadioTL = index => {
        const currentOptions = getValues(`formData.${index}.radioTextData`) || [];

        const newOption = { label: '', Key: '', answer:"" };
        const updatedOptions = [...currentOptions, newOption];

        setValue(`formData.${index}.radioTextData`, updatedOptions);
        update(`formData.${index}.radioTextData`, updatedOptions);

    };

    const handleRemoveRadioTL = (index, optionIndex) => {
        const currentOptions = getValues(`formData.${index}.radioTextData`) || [];
        currentOptions.splice(optionIndex, 1);
        setValue(`formData.${index}.radioTextData`, [...currentOptions]);
        update(`formData.${index}.radioTextData`, [...currentOptions]);
    };


    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            // console.log('Form data changed:', value);
        });

        return () => subscription.unsubscribe();
    }, [watch]);
   
    const apinameMemberships = process.env.REACT_APP_API_MEMBERSHIPS?.toString();

    const { data:membershipData, error:membershipError, isFetching:membershipIsFetching, refetch:membershipRefetch } = useFetch(apinameMemberships,1,100000);
  
  
   
    useEffect(()=>{
      if(membershipData){
        let data = membershipData?.data?.map((e)=>{return {label:e.membership_name, value:e.id}})
        setMembershipOptions(data)
      }
    },[membershipData])
  
    const onChangemembership = (value) => {
      setValue('membership_id',value)
      setMembershipValue(value)
      clearErrors('membership_id')
  
    };
    
    const onSearchmembership = (value) => {
      console.log('search:', value);
    };


    return (
        <>
            <div className='p-4 space-y-4 '>


                <div className="bg-white shadow rounded-lg p-4 w-10/12 sm:w-full">
                    {/* <div className="text-gray-600 mb-2">Form</div> */}
                    <div className="max-h-fit bg-white-100 flex items-center justify-center text-black-500">
                        <div className='w-full'>
                            <form onSubmit={handleSubmit(onSubmit)} className='w-full max-w-3xl m-auto p-4'>
                                <Card className="m-2 p-2 !mb-[5rem] w-full !shadow-lg !shadow-pink-200">
                                    <div className='flex flex-col'>
                                        <input
                                            type="text"
                                            id={`form-title`}
                                            name={`form_name`}
                                            placeholder={"Write Form Title"}
                                            className="!bg-transparent m-2 border-b-2 outline-0 border border-gray-700 rounded-lg p-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-400"
                                            {...register('form_name', { required: { value: true, message: 'Title is required' } })}
                                            // register={register}
                                            label={"Title"}
                                        />
                                        <p style={{ color: "Red" }} className='m-2'>{errors?.form_name?.message}</p>
                                        <input
                                            type="text"
                                            id={`form-desc`}
                                            name={`form-desc`}
                                            placeholder={"Write Form Description"}
                                            className="!bg-transparent m-2  border-b-2 outline-0 border border-gray-700 rounded-lg p-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-400"
                                            {...register('form_description', { required: { value: true, message: 'Description is required' } })}
                                        />
                                        <p style={{ color: "Red" }} className='m-2'>{errors?.form_description?.message}</p>
                                        <label htmlFor="" className='px-2 font-bold'>File</label>
                                        <select
                                            id="file_name"
                                            className="!bg-transparent mx-2 border-b-2 outline-0 border border-gray-700 rounded-lg p-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-400"
                                            value={getValues('file_name') || ""}
                                            disabled
                                        >
                                            <option value="">{details?.file_name || "No file selected"}</option>
                                        </select>
                                        <p style={{ color: "Red" }} className='m-2'>{errors?.file_name?.message}</p>


                                          
                                        <label htmlFor="" className='px-2 font-bold'>Is Peoria</label>
                                                <select
                                                    label="Is Peoria"
                                                    className="!bg-transparent mx-2  border-b-2 outline-0 border border-gray-700 rounded-lg p-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-400"
                                                    name={`isPeoria`}
                                                    {...register(`isPeoria`, { required: { value: true, message: 'Field is required' } })}
                                                    // value={getValues('isPeoria') }
                                                    onChange={(e)=>{
                                                        setValue('isPeoria',e.target.value)
                                                    }}
                                                >
                                                    <option value="">Select</option>
                                                    <option value={1}>Yes</option>
                                                    <option value={0}>No</option>
                                                    
                                                </select>
                                                <p style={{ color: "Red" }} className='m-2'>{errors?.isPeoria?.message}</p>


                                                <label htmlFor="" className='px-2 font-bold'>Membership Name</label>
                                                <Select
                                    {...register('membership_id', { required: { value: false, message: 'Membership Is Required' } })}

                                    className="!bg-transparent mx-2  border-b-2 outline-0 border border-gray-700 rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-blue-400"

                style={{height:'40px'}}
    showSearch
    value={membershipValue}
    placeholder="Select a Membership"
    optionFilterProp="label"
    onChange={onChangemembership}
    onSearch={onSearchmembership}
    options={membershipOptions}
    />
                                    </div>
                                </Card>
                                {fields.map((field, index) => (
                                    <FormSection
                                        key={field.id}
                                        index={index}
                                        formData={field}
                                        handleInputChange={handleInputChange}
                                        handleRadioAddOption={handleRadioAddOption}
                                        handleRadioRemoveOption={handleRadioRemoveOption}
                                        handleAddOption={handleAddOption}
                                        handleRemoveOption={handleRemoveOption}
                                        handleRemoveField={handleRemoveField}
                                        handleDropdownChange={handleDropdownChange}
                                        handleRemoveDropDown={handleRemoveDropDown}
                                        handleAddDDOption={handleAddDDOption}
                                        handleAddRadioTL={handleAddRadioTL}
                                        handleRemoveRadioTL={handleRemoveRadioTL}
                                        register={register}
                                        errors={errors}
                                        unregister={register}
                                        control={control}
                                        addSections={addSection}
                                    />
                                ))}

                                <div className="flex items-center justify-around mt-4 flex-col sm:flex-row">
                                    <div className="mt-6 text-center">
                                        <button className="bg-pink-600 text-white px-6 py-2 rounded-lg shadow-md hover:bg-pink-400 w-56" type='submit'>Submit</button>
                                    </div>
                                    {/* <button type='submit' className="max-w-max block text-centerou bg-pink-600 text-white py-2 rnded-md hover:bg-pink-700 p-6">
                                        Submit form
                                        <div className='flex justify-center'> */}
                                    {/* <div>  //TODO: loader on submit button call
                                                {isLoading && <div className="w-6 h-6 rounded-full animate-spin border-4 border-dashed border-white border-t-transparent"></div>}
                                            </div> */}
                                    {/* </div> */}
                                    {/* </button> */}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={state.open}
                onClose={handleClose}
                TransitionComponent={state.Transition}
                message={error?.response?.data?.message}
                key={state?.Transition?.name}
                autoHideDuration={3000}
            />
        </>
    );
};

export default Edit_Form;