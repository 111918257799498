
import React, { useEffect, useLayoutEffect, useState } from 'react'
import FormSection from './FormSection';
import Card from '../UI Component/Card';
import { useForm, useFieldArray } from "react-hook-form"
import { Snackbar } from '@mui/material';
import { useCreate } from '../../hooks/Api';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useFetch } from '../../hooks/Api';
import { Select } from 'antd';
const Form = () => {

    const apiname = process.env.REACT_APP_API_FORMS?.toString();
    const { mutate, data, isError, isSuccess, error } = useCreate(apiname);
    const navigate = useNavigate()
    const [files, setFiles] = useState([])
    const [loading, setLoading] = useState(false)

    useLayoutEffect(() => {
        getFilesData()
    }, [])

    const getFilesData = async () => {
        try {
            setLoading(true)
            const localStorageData = localStorage.getItem('items');
            const tokenData = localStorageData ? JSON.parse(localStorageData) : null;
            const res = await axios.get(`${process.env.REACT_APP_BASE_URL}api/getAllFiles`, {
                headers: {
                    'authorization': `Bearer ${tokenData?.token}`
                }
            });
            setFiles(res.data.results)
            setLoading(false)
        } catch (error) {
            console.log("🚀 ~ getFilesData ~ error:", error)
            setLoading(false)
        }
    }

    const { register, control, handleSubmit, setValue, getValues, watch, formState: { errors }, value, replace, clearErrors } = useForm({
        defaultValues: {
            formData: [{
                type: 'short',
                radioOptions: [""],
                checkBoxOption: [""],
                dropdownOption: [{ label: "", value: "" }],
                label: '',
                radioText: false,
                radioTextData:[{ label:"", Key:"", answer:"" }],
                answer: "",
                Key: ""
            }]
        }
    });

    const { fields, append, remove, update, insert } = useFieldArray({
        control,
        name: 'formData'
    });

    const onSubmit = data => {
        const FinalData = handleformData(data)
        mutate(FinalData)
    };


    const handleformData = (submitData) => {
        const formFields = [];

        submitData.formData.map((key, index) => {
            if (key.type === "short" || key.type === "paragraph" || key.type == "date" || key.type == "sign pad") {
                delete key.checkBoxOption;
                delete key.dropdownOption;
                delete key.radioOptions;
                delete key.radioText;
                delete key.radioTextData;
                if (key.type === "date") {
                    key.format = "DD/MM/YYYY"
                }
            }

            if(key.type === 'button'){
                delete key.required
                delete key.Key
                delete key.checkBoxOption;
                delete key.dropdownOption;
                delete key.radioOptions;
                delete key.radioText;
                delete key.radioTextData;
                delete key.date;
                delete key.answer;
                delete key.button;
            }
            if (key.type === "h1") {
                delete key.answer;
                delete key.checkBoxOption;
                delete key.dropdownOption;
                delete key.radioOptions;
                delete key.radioText;
                delete key.radioTextData;
                delete key.required;   
                
                if (key.type === "date") {
                  key.format = "DD/MM/YYYY";
                }
              }

              if (key.type === "normal text") {
                delete key.answer;
                delete key.checkBoxOption;
                delete key.dropdownOption;
                delete key.radioOptions;
                delete key.radioText;
                delete key.radioTextData;
                delete key.required;   
                
                if (key.type === "date") {
                  key.format = "DD/MM/YYYY";
                }
              }
              if (key.type === "h2") {
                delete key.answer;
                delete key.checkBoxOption;
                delete key.dropdownOption;
                delete key.radioOptions;
                delete key.radioText;
                delete key.radioTextData;
                delete key.required;   
                
                if (key.type === "date") {
                  key.format = "DD/MM/YYYY";
                }
              }
              if (key.type === "h3") {
                delete key.answer;
                delete key.checkBoxOption;
                delete key.dropdownOption;
                delete key.radioOptions;
                delete key.radioText;
                delete key.radioTextData;
                delete key.required;   
                
                if (key.type === "date") {
                  key.format = "DD/MM/YYYY";
                }
              }
              if (key.type === "h4") {
                delete key.answer;
                delete key.checkBoxOption;
                delete key.dropdownOption;
                delete key.radioOptions;
                delete key.radioText;
                delete key.radioTextData;
                delete key.required;   
                
                if (key.type === "date") {
                  key.format = "DD/MM/YYYY";
                }
              }
              if (key.type === "h5") {
                delete key.answer;
                delete key.checkBoxOption;
                delete key.dropdownOption;
                delete key.radioOptions;
                delete key.radioText;
                delete key.radioTextData;
                delete key.required;   
                
                if (key.type === "date") {
                  key.format = "DD/MM/YYYY";
                }
              }

            if (key.type === "radio") {
                delete key.checkBoxOption;
                delete key.date;
                delete key.dropdownOption;
    
                if (key.radioOptions) {
                    key.options = key.radioOptions.map((option, index) => ({
                        [`option ${index + 1}`]: option
                    }));
                }
                delete key.radioOptions;
            }
    

            if (key.type === "checkbox") {
                delete key.date;
                delete key.dropdownOption;
                delete key.radioText;
                delete key.radioOptions;
                delete key.radioTextData;

                if (key.checkBoxOption) {
                    key.options = key.checkBoxOption.map((option, index) => ({
                        [`option ${index + 1}`]: option
                    }));
                }
                delete key.checkBoxOption;
            }

            if (key.type === "dropdown") {
                delete key.date;
                delete key.radioText;
                delete key.radioOptions;
                delete key.checkBoxOption;
                delete key.radioTextData;

                if (key.dropdownOption) {
                    key.options = key.dropdownOption
                }
                delete key.dropdownOption;
            }
            formFields.push(key)
        })
        const finalObj = {
            form_name: submitData.form_name,
            file_name: submitData.file_name,
            form_description: submitData.form_description,
            form_fields: formFields,
            isPeoria:submitData?.isPeoria == 1 ? true : false,
            membership_id:submitData.membership_id
        }
        return finalObj
    }

    const addSection = (index) => {
        insert(index + 1,{
            type: 'short',
            radioOptions: [""],
            checkBoxOption: [""],
            dropdownOption: [{ label: "", value: "" }],
            label: '',
            radioText: false,
            radioTextData:[{label:"",Key:"",answer:""}],
            answer: "",
            Key: ""
        });
    };

    const handleRemoveField = index => {
        remove(index);
    };

    const handleInputChange = (index, field, value) => {

        console.log(field)

        setValue(`formData.${index}.${field}`, value);
        update(`formData.${index}.${field}`, value)
    };

    const handleRadioAddOption = index => {
        const currentOptions = getValues(`formData.${index}.radioOptions`) || [];
        setValue(`formData.${index}.radioOptions`, [...currentOptions, '']);
        update(`formData.${index}.radioOptions`, [...currentOptions, ''])
    };

    const handleRadioRemoveOption = (index, optionIndex) => {
        const currentOptions = getValues(`formData.${index}.radioOptions`) || [];
        currentOptions.splice(optionIndex, 1);
        setValue(`formData.${index}.radioOptions`, [...currentOptions]);
        update(`formData.${index}.radioOptions`, [...currentOptions])
    };

    const handleAddOption = (index, optionType) => {
        const currentOptions = getValues(`formData.${index}.${optionType}`) || [];
        setValue(`formData.${index}.${optionType}`, [...currentOptions, '']);
        update(`formData.${index}.${optionType}`, [...currentOptions, '']);

    };

    const handleRemoveOption = (index, optionIndex) => {
        const currentOptions = getValues(`formData.${index}.checkBoxOption`) || [];
        currentOptions.splice(optionIndex, 1);
        setValue(`formData.${index}.checkBoxOption`, [...currentOptions]);
        update(`formData.${index}.checkBoxOption`, [...currentOptions]);
    };

    const handleAddDDOption = index => {
        const currentOptions = getValues(`formData.${index}.dropdownOption`) || [];

        const newOption = { label: '', value: '' };
        const updatedOptions = [...currentOptions, newOption];

        setValue(`formData.${index}.dropdownOption`, updatedOptions);
        update(`formData.${index}.dropdownOption`, updatedOptions);

    };

    const handleAddRadioTL = index => {
        const currentOptions = getValues(`formData.${index}.radioTextData`) || [];

        const newOption = { label: '', Key: '', answer:"" };
        const updatedOptions = [...currentOptions, newOption];

        setValue(`formData.${index}.radioTextData`, updatedOptions);
        update(`formData.${index}.radioTextData`, updatedOptions);

    };

    const handleRemoveRadioTL = (index, optionIndex) => {
        const currentOptions = getValues(`formData.${index}.radioTextData`) || [];
        currentOptions.splice(optionIndex, 1);
        setValue(`formData.${index}.radioTextData`, [...currentOptions]);
        update(`formData.${index}.radioTextData`, [...currentOptions]);
    };

    const handleRemoveDropDown = (index, optionIndex) => {
        const currentOptions = getValues(`formData.${index}.dropdownOption`) || [];
        currentOptions.splice(optionIndex, 1);
        setValue(`formData.${index}.dropdownOption`, [...currentOptions]);
        update(`formData.${index}.dropdownOption`, [...currentOptions]);
    };

    const handleDropdownChange = (index, idx, e, field) => {
        const currentOptions = getValues(`formData.${index}.dropdownOption`) || [];
        currentOptions[idx][field] = e.target.value;
        setValue(`formData.${index}.dropdownOption`, [...currentOptions]);
        update(`formData.${index}.dropdownOption`, [...currentOptions]);
    };


    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            // console.log('Form data changed:', value);
        });

        return () => subscription.unsubscribe();
    }, [watch]);



    const [state, setState] = React.useState({
        open: false
    });
    const handleClose = () => {
        setState({
            ...state,
            open: false,
        });
    };

    useEffect(() => {
        if (isSuccess) {
            setState({ open: true })
            navigate("/layout/form")
        }
        else if (isError) {
            // console.log(isError, "isError");
            setState({ open: true })
        }
    }, [data, error, isSuccess])


    const apinameMemberhsips = process.env.REACT_APP_API_MEMBERSHIPS?.toString();
    const { data:membershipData, error:membershipError, isFetching:membershipIsFetching, refetch:membershipRefetch } = useFetch(apinameMemberhsips,1,100000);
  
  
    const [membershipOptions,setMembershipOptions] = useState([])
    useEffect(()=>{
      if(membershipData){
        let data = membershipData?.data?.map((e)=>{return {label:e.membership_name, value:e.id}})
        setMembershipOptions(data)
      }
    },[membershipData])
  
    const onChangemembership = (value) => {
      setValue('membership_id',value)
      setMembershipOptions(value)
      clearErrors('membership_id')
  
    };
    
    const onSearchmembership = (value) => {
      console.log('search:', value);
    };
    
    
    return (
        <>
            <div className='p-4 space-y-4 '>
                <div className="bg-white shadow rounded-lg p-4 w-10/12">
                    <div className="max-h-fit bg-white-100 flex items-center justify-center text-black-500">
                        <div className='w-full'>
                            {
                                !loading && (
                                    <form onSubmit={handleSubmit(onSubmit)} className='w-full max-w-3xl m-auto p-4'>
                                        <Card className="m-2 p-2 !mb-[5rem] w-full !shadow-lg !shadow-pink-200">
                                            <div className='flex flex-col'>
                                                <input
                                                    type="text"
                                                    id={`form-title`}
                                                    name={`form_name`}
                                                    placeholder={"Write Form Title"}
                                                    className="!bg-transparent m-2 border-b-2 outline-0 border border-gray-700 rounded-lg p-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-400"
                                                    {...register('form_name', { required: { value: true, message: 'Title is required' } })}
                                                    // register={register}
                                                    label={"Title"}
                                                />
                                                <p style={{ color: "Red" }} className='m-2'>{errors?.form_name?.message}</p>
                                                <input
                                                    type="text"
                                                    id={`form-desc`}
                                                    name={`form-desc`}
                                                    placeholder={"Write Form Description"}
                                                    className="!bg-transparent m-2  border-b-2 outline-0 border border-gray-700 rounded-lg p-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-400"
                                                    {...register('form_description', { required: { value: true, message: 'Description is required' } })}
                                                />
                                                <p style={{ color: "Red" }} className='m-2'>{errors?.form_description?.message}</p>

                                                <label htmlFor="" className='px-2 font-bold'>File</label>
                                                <select
                                                    label="Short Answer"
                                                    className="!bg-transparent mx-2  border-b-2 outline-0 border border-gray-700 rounded-lg p-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-400"
                                                    name={`file_name`}
                                                    {...register(`file_name`, { required: { value: true, message: 'File name is required' } })}
                                                >
                                                    <option value="">Select</option>
                                                    {
                                                        files?.map((f, idx) => 
                                                            <option value={f} key={idx}>{f}</option>
                                                        )
                                                    }
                                                </select>
                                                <p style={{ color: "Red" }} className='m-2'>{errors?.file_name?.message}</p>
                                                    
                                                    <label htmlFor="" className='px-2 font-bold'>Is Peoria</label>
                                                <select
                                                    label="Is Peoria"
                                                    className="!bg-transparent mx-2  border-b-2 outline-0 border border-gray-700 rounded-lg p-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-400"
                                                    name={`isPeoria`}
                                                    // onChange={(e)=>{
                                                    //     setValue('isPeoria',e.target.value)
                                                    // }}
                                                    {...register(`isPeoria`, { required: { value: true, message: 'Field is required' } })}
                                                >
                                                    <option value="">Select</option>
                                                    <option value={1}>Yes</option>
                                                    <option value={0}>No</option>
                                                    
                                                </select>
                                                <p style={{ color: "Red" }} className='m-2'>{errors?.isPeoria?.message}</p>

                                                <label htmlFor="" className='px-2 font-bold'>Membership Name</label>
                                                <Select
                                    {...register('membership_id', { required: { value: false, message: 'Membership Is Required' } })}

                                    className="!bg-transparent mx-2  border-b-2 outline-0 border border-gray-700 rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-blue-400"

                style={{height:'40px'}}
    showSearch
    placeholder="Select a Membership"
    optionFilterProp="label"
    onChange={onChangemembership}
    onSearch={onSearchmembership}
    options={membershipOptions}
    />

                                            </div>
                                        </Card>
                                        {fields.map((field, index) => (
                                            <FormSection
                                                key={field.id}
                                                index={index}
                                                formData={field}
                                                handleInputChange={handleInputChange}
                                                handleRadioAddOption={handleRadioAddOption}
                                                handleAddRadioTL={handleAddRadioTL}
                                                handleRadioRemoveOption={handleRadioRemoveOption}
                                                handleAddOption={handleAddOption}
                                                handleRemoveOption={handleRemoveOption}
                                                handleRemoveField={handleRemoveField}
                                                handleDropdownChange={handleDropdownChange}
                                                handleRemoveDropDown={handleRemoveDropDown}
                                                handleRemoveRadioTL={handleRemoveRadioTL}
                                                handleAddDDOption={handleAddDDOption}
                                                register={register}
                                                errors={errors}
                                                unregister={register}
                                                control={control}
                                                addSections={addSection}
                                            />
                                        ))}
                                        <div className="flex items-center justify-around mt-4 flex-col sm:flex-row">
                                            <div className="mt-6 text-center">
                                                <button className="bg-pink-600 text-white px-6 py-2 rounded-lg shadow-md hover:bg-pink-400 w-56" type='submit'>Submit</button>
                                            </div>
                                        </div>
                                    </form>)
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Snackbar //TODO: snackbar on api call
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={state.open}
                onClose={handleClose}
                TransitionComponent={state.Transition}
                message={error?.response?.data?.message || data?.message}
                key={state?.Transition?.name}
                autoHideDuration={3000}
            />
        </>
    );
};

export default Form;