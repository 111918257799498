import React, { useState } from 'react';
import Header from './subComponents/Header';
import Sidebar from './subComponents/Sidebar';
import MobileMenu from './subComponents/MobileMenu';
import { Route, Routes } from "react-router-dom";
import Dashboard from '../pages/Dashboard';
import Profile from '../pages/Profile';
import Add_Register_Store from '../pages/Register Store Manager/Add_Register_Store'
import Edit_Register_Store from '../pages/Register Store Manager/Edit_Register_Store'
import List_Register_Store_Manager from '../pages/Register Store Manager/List_Register_Store_Manager'
import List_User from '../pages/User List/List_User'
import PageNotFound from '../pages/PageNotFound';
import Forms from '../pages/Admin/From/Index';
import Form from '../component/Form/Form';
import Edit_Form from '../component/Form/Edit_Form';
import UploadFileFormat from '../pages/Upload file format/upload_file_format'
import List_Files from '../pages/Upload file format/List_Files';
import Edit_User from '../pages/User List/Edit_User';
import List_User_Form from '../pages/User List/List_User_Form';
import List_City from '../pages/City/List_City';
import Add_City from '../pages/City/Add_City';
import Edit_City from '../pages/City/Edit_City';
import List_Zipcode from '../pages/Zipcode/List_Zipcode';
import Add_Zipcode from '../pages/Zipcode/Add_Zipcode';
import Edit_Zipcode from '../pages/Zipcode/Edit_Zipcode';
import List_State from '../pages/State/List_State';
import Add_State from '../pages/State/Add_State';
import Edit_State from '../pages/State/Edit_State';
import List_Phone from '../pages/Phone/List_Phone';
import Add_Phone from '../pages/Phone/Add_Phone';
import Edit_Phone from '../pages/Phone/Edit_Phone';
import List_Address from '../pages/Address/List_Address';
import Add_Address from '../pages/Address/Add_Address';
import Edit_Address from '../pages/Address/Edit_Address';
import List_Store from '../pages/Store/List_Store';
import Add_Store from '../pages/Store/Add_Store';
import Edit_Store from '../pages/Store/Edit_Store';
import List_Membership from '../pages/Membership/List_Membership';
import Add_Membership from '../pages/Membership/Add_Membership';
import Edit_Membership from '../pages/Membership/Edit_Membership';

const Layout = () => {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    };

    const closeMobileMenu = () => {
        setMobileMenuOpen(false);
    };

    return (
        <div className="bg-gray-100 min-h-screen">
            <Header toggleMobileMenu={toggleMobileMenu} />
            <div className="flex">
                <Sidebar />
                <main className="flex-1 p-4 w-fit sm:w-full overflow-x-auto">
                    <Routes>
                        <Route path="/" element={<Dashboard />} />
                        <Route path="/profile" element={<Profile />} />
                        <Route path="/Addstore" element={<Add_Register_Store />} />
                        <Route path="/Editstore/:id" element={<Edit_Register_Store />} />
                        <Route path="/liststore" element={<List_Register_Store_Manager />} />
                        <Route path="/listUser" element={<List_User />} />
                        <Route path="/userforms" element={<List_User_Form />} />
                        <Route path="/EditUser/:id" element={<Edit_User />} />
                        <Route path="/form" element={<Forms />} />
                        <Route path="/addForm" element={<Form />} />
                        <Route path="/editForm/:id" element={<Edit_Form />} />
                        <Route path="/fileUpload" element={<UploadFileFormat />} />
                        <Route path="/listFiles" element={<List_Files />} />

                        <Route path="/cities" element={<List_City />} />
                        <Route path="/AddCity" element={<Add_City />} />
                        <Route path="/EditCity/:id" element={<Edit_City />} />


                        <Route path="/zipcodes" element={<List_Zipcode />} />
                        <Route path="/AddZipcode" element={<Add_Zipcode />} />
                        <Route path="/EditZipcode/:id" element={<Edit_Zipcode />} />

                        <Route path="/states" element={<List_State />} />
                        <Route path="/AddState" element={<Add_State />} />
                        <Route path="/EditState/:id" element={<Edit_State />} />


                        <Route path="/phones" element={<List_Phone />} />
                        <Route path="/AddPhone" element={<Add_Phone />} />
                        <Route path="/EditPhone/:id" element={<Edit_Phone />} />

                        <Route path="/addresses" element={<List_Address />} />
                        <Route path="/AddAddress" element={<Add_Address />} />
                        <Route path="/EditAddress/:id" element={<Edit_Address />} />


                        <Route path="/stores" element={<List_Store />} />
                        <Route path="/AddNewStore" element={<Add_Store />} />
                        <Route path="/EditNewStore/:id" element={<Edit_Store />} />


                        <Route path="/memberships" element={<List_Membership />} />
                        <Route path="/AddMembership" element={<Add_Membership />} />
                        <Route path="/EditMembership/:id" element={<Edit_Membership />} />
                        
                        <Route path="*" element={<PageNotFound />} />
                    </Routes>
                </main>
            </div>
            <MobileMenu isOpen={isMobileMenuOpen} closeMobileMenu={closeMobileMenu} />
        </div>
    );
};

export default Layout;
