import React, { useEffect, useState } from 'react';
import { useFetch } from '../../hooks/Api';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { FaRegEdit, FaWpforms } from 'react-icons/fa';
import { MdDeleteForever } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import CustomModal from '../../component/UI Component/Modal';
import { Snackbar } from '@mui/material';
import Loader from '../../component/UI Component/Loader';
import { Select } from 'antd';
const List_User = () => {
    // alert('here')
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [state, setState] = useState({
        open: false
    });
    const [userId, setUserId] = useState();
    const [response, setResponse] = useState()
    const [currentPage, setCurrentPage] = useState(1);
    const [inputValue, setInputValue] = useState("");
    const [currentSearch, setCurrentSearch] = useState("search");
    const itemsPerPage = 10;
    const localStorageData = localStorage.getItem('items');
    const tokenData = localStorageData ? JSON.parse(localStorageData) : null;
    const apiname = process.env.REACT_APP_API_USERS.toString();
    const deleteapiname = process.env.REACT_APP_API_USER_UPDATE_DELETE.toString();
    const apiData = tokenData?.roles === 'store' ? `${apiname}/${tokenData?.storeId}` : `${apiname}`;


    const buildQueryParams = (inputValue, currentSearch, currentPage) => {
        const queryParams = {
            page: currentPage,
            limit: 10,
        };
    
        if (inputValue !== "") {
            switch (currentSearch) {
                case 'search':
                    queryParams.search = inputValue;
                    break;
                case 'firstName':
                    queryParams.firstName = inputValue;
                    break;
                case 'lastName':
                    queryParams.lastName = inputValue;
                    break;
                case 'email':
                    queryParams.email = inputValue;
                    break;
                case 'dob':
                    queryParams.dob = inputValue;
                    break;
                default:
                    break;
            }
        }
    
        return new URLSearchParams(queryParams).toString();
    };

    // const query = new URLSearchParams({
        
    //     search: inputValue !== "" && currentSearch === 'search' ? inputValue : "",
    //     firstName: inputValue !== "" && currentSearch === 'firstName'? inputValue : "",
    //     lastName: inputValue !== "" && currentSearch === 'lastName'? inputValue : "",
    //     email: inputValue !== "" && currentSearch === 'email'? inputValue : "",
    //     dob: inputValue !== "" && currentSearch === 'dob'? inputValue : "",
    //     page: currentPage,
    //     limit: 10,
    // }).toString();

    console.log(inputValue)

    const query = buildQueryParams(inputValue, currentSearch, currentPage);
    const { data, error, isFetching, refetch } = useFetch(`${apiData}?${query}`);

    useEffect(() => {
        if (isFetching && error === null || inputValue === "") {
            refetch();
        }
    }, [inputValue, currentPage, inputValue]);

    if (!data) return null;

    const totalPages = Math.ceil(data.totalRows / itemsPerPage);

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
        setCurrentPage(1);
    };

    const handlePageChange = (event, pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleClose = () => {
        setState(prevState => ({ ...prevState, open: false }));
    };

    const getSnackbarStyle = () => {
        return response?.status === 200
            ? { backgroundColor: 'green', color: 'white' }
            : { backgroundColor: 'red', color: 'white' };
    };

    const removeUser = (userId) => {
        setUserId(userId);
        setOpen(true);
    };

    const handleYes = () => {
        setOpen(false);
        deleteUser();
    };

    const handleNo = () => {
        setOpen(false);
    };

    const deleteUser = async () => {
        const localData = JSON.parse(localStorage.getItem("items"));
        const res = await axios.delete(`${process.env.REACT_APP_BASE_URL}${deleteapiname}/${userId}`, {
            headers: { 'authorization': `Bearer ${localData?.token}` }
        });
        setResponse(res);
        setState({ open: true });
        await refetch();
    };
// console.log('error', error)

const onChange = (value) => {
    setCurrentSearch(value)
  };
  
  const onSearch = (value) => {
    setInputValue(value);
    setCurrentPage(1);
    // console.log('search:', value);
  };

// console.log(data === 'No user found !' || data === 'Store Id not found' || data === "There is no user yet!" || error)
  return (
        <>
            {(data === 'No user found !' || data === 'Store Id not found' || data === "There is no user yet!" || error) ? (
                <>
                    <p className='flex justify-center text-3xl'>{error ? error?.message : "No user Found"}</p>
                </>
            ) : (
                <>


                <div className='flex justify-end'>

                
                    <input
                        type='search'
                        value={inputValue}
                        onChange={handleInputChange}
                        className='flex w-96 border border-gray-300 rounded-lg p-2 '
                        placeholder={`Search User`}
                    />

<Select
    showSearch
    className='flex w-96 border h-10 border-gray-300 rounded-lg '
    defaultValue={{
        value: 'search',
        label: 'By Mobile',
      }}
    optionFilterProp="label"
    onChange={onChange}
    onSearch={onSearch}
    options={[
        {
            value: 'search',
            label: 'By Mobile',
          },
      {
        value: 'firstName',
        label: 'By First Name',
      },
      {
        value: 'lastName',
        label: 'By Last Name',
      },
      {
        value: 'email',
        label: 'By Email',
      },
      {
        value: 'dob',
        label: 'By DOB',
      },
    ]}
  />
  </div>
                    <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-white uppercase bg-pink-600 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="px-6 py-3">Sr No.</th>
                                    <th scope="col" className="">First Name</th>
                                    <th scope="col" className="">Last Name</th>
                                    <th scope="col" className="">Mobile Number</th>
                                    {/* <th scope="col" className="px-6 py-3">Email</th> */}
                                    <th scope="col" className="">Date Of Birth</th>
                                    {/* <th scope="col" className="px-6 py-3">Store ID</th> */}
                                    {tokenData?.roles !== "store" ? <th scope="col" className="px-6 py-3">Store Name</th>:null}
                                    <th scope="col" className="px-6 py-3">Email</th>
                                    {
                                        tokenData.roles !== "store" ? (
                                            <th scope="col" className="px-6 py-3">Action</th>
                                        ) : null
                                    }
                                    <th scope="col" className="px-6 py-3">Forms</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.data?.map((e, index) =>{
                                    let personal_details = e?.personal_details ? JSON.parse(e?.personal_details): null
                                    return(
                                        <tr key={e.id} className="odd:bg-white odd:dark:bg-gray-900 even:bg-pink-50 even:dark:bg-pink-200 border-b dark:border-pink-700">
                                            <td className="px-6 py-4" >{index + 1}</td>
                                            <td className="px-6 py-4" >{personal_details?.first_name? personal_details?.first_name :'-'}</td>
                                            <td className="px-6 py-4" >{personal_details?.last_name? personal_details?.last_name :'-'}</td>
                                            <td className="px-6 py-4" >{e?.mobile}</td>
                                            <td className="px-6 py-4" style={{minWidth:'150px'}}>{personal_details?.dob? personal_details?.dob :'-'}</td>
                                            {/* <td className="px-6 py-4" >{e?.storeId}</td> */}
                                            {tokenData.roles !== "store" ? <td className="px-6 py-4" >{e?.store_name}</td>:null}
                                            <td className="px-6 py-4" >{e?.email}</td>
                                            {
                                                tokenData.roles !== "store" ? (
                                                    <td className='px-6 py-4 items-center'>
    
                                                        <div className='grid grid-cols-2'>
                                                            <button onClick={() => {
                                                                navigate(`/layout/EditUser/${e.id}`)
                                                            }}>
                                                                <FaRegEdit size={20} style={{ color: '#DB2777' }} />
                                                            </button>
                                                            <button
                                                                onClick={() => removeUser(e.id)}
                                                                className='mx-1'
                                                            >
                                                                <MdDeleteForever size={23} style={{ color: '#DB2777' }} />
                                                            </button>
    
                                                        </div>
    
                                                    </td>
                                                ) : null
                                            }
                                            <td className='px-6 py-4'>
                                                <button onClick={() => {
                                                    navigate(`/layout/userforms`)
                                                    localStorage.setItem("userID", e.id)
                                                }}>
                                                    <FaWpforms size={20} style={{ color: '#DB2777' }} />
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                } )}
                            </tbody>
                        </table>
                    </div>
                    <div className="flex justify-end mt-4">
                        <Stack spacing={2}>
                            <Pagination
                                count={totalPages}
                                page={currentPage}
                                onChange={handlePageChange}
                                // color="primary"
                                variant="outlined"
                                shape="rounded"
                                sx={{
                                    '& .MuiPaginationItem-root': {
                                        color: '#DB2777',
                                        borderColor: '#DB2777',
                                    },
                                    '& .Mui-selected': {
                                        backgroundColor: '#DB2777',
                                        color: '#ffffff',
                                    },
                                    '& .MuiPaginationItem-ellipsis': {
                                        // color: '#DB2777',
                                    },
                                }}
                            />
                        </Stack>
                    </div>
                </>
            )}
            <CustomModal
                open={open}
                onClose={handleNo}
                onYes={handleYes}
                onNo={handleNo}
                text={"Are you sure you want to delete?"}
            />
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={state.open}
                onClose={handleClose}
                autoHideDuration={3000}
                ContentProps={{
                    style: getSnackbarStyle(),
                }}
                message={response?.data?.message || response?.data?.message}
            />
            {/* {
                isFetching ? (
                    <Loader />
                ) : null
            } */}
        </>
    );
};

export default List_User;
